import React from "react";
import { images } from "../config/ConstImg";

export const Features = () => {
    return (
        <div className="container">
            <div className="mt-5">
                <div className="row mt-5">
                    <div className="text-center mt-4 mb-4">
                <h3 className="home-service-content-text-box-txt my-2">How Marketplace Security on Demand Works</h3>
                <p className="custom-head app-color">We’re not just a marketplace—we’re building a structure that benefits security freelancers who are passionate about their work.</p>
                </div>
                           
                    <div className="col-12 col-md-6">
                        <div className="about-us-box-play-box01-left">
                            <div>
                            <ul className="initial-content ul-lister">
                                <li>
                                    <strong>1. Select Your Service </strong> <br />
                                   <p> Choose the security service you need and complete a simple questionnaire.</p>
                                </li>
                                <li>
                                    <strong>2. Targeted Matching </strong> <br />
                                    <p>Our system connects you with vetted SIA-licensed security professionals in your region.</p>
                                </li>
                                <li>
                                    <strong>3. Hire Local Talent </strong> <br />
                                    <p>We prioritize local labor, supporting communities while reducing costs for businesses.</p>
                                </li>
                            </ul>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-12 col-md-6 image-contain">
                        <img src={images.chain} className="img-fluid rounded-3" alt="Security Process" />
                    </div>
                </div>

                <div className="row row-reverse mt-5">
                    <div className="col-12 col-md-6 image-contain">
                        <img src={images.payas} className="img-fluid rounded-3" alt="Secure Payment" />
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="about-us-box-play-box01-left">
                            <p className="home-service-content-text-box-txt  my-2">
                                Why Choose Us?
                            </p>
                            <p className="custom-head app-color">Insta-guard offers personalized security solutions tailored to user needs.</p>
                            <ul className="base-color list-font-size">
                                <li className="list-bottom"><b>Save Thousands Monthly:</b> <span className="app-desc-txt-color"> Reduce security expenses while enhancing operational efficiency.</span></li>
                                <li className="list-bottom"><b>Affordable Rates:</b> <span className="app-desc-txt-color">Starting from £13.57 per hour, ensuring quality security at lower costs.</span></li>
                                <li className="list-bottom"><b>Real-Time Monitoring:</b>  <span className="app-desc-txt-color"> Geo-fencing, GPS tracking, and automated monitoring for security professionals.</span></li>
                                <li className="list-bottom"><b>Secure Communication:</b> <span className="app-desc-txt-color"> Use our VOIP system to contact security staff without sharing personal numbers.</span></li>
                                <li className="list-bottom"><b>Thousands of Professionals:</b>  <span className="app-desc-txt-color">Access a large network of vetted, DBS-checked security experts. </span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                <p className="home-service-content-text-box-txt  my-2">
                                A Platform Built for Security Freelancers
                            </p>
                    <div className="col-12 col-md-6">
                        <div className="about-us-box-play-box01-left">
                           
                            <p className="custom-head app-color">We’re not just a marketplace—we empower security freelancers.</p>
                            <ul className="base-color list-font-size">
                                <li className="list-bottom"><b>Fair Compensation:</b><span className="app-desc-txt-color">The majority of earnings go directly to professionals, with a small platform fee.</span></li>
                                <li className="list-bottom"><b>Empowering Freelancers:</b><span className="app-desc-txt-color"> More job opportunities, flexible work conditions, and direct client connections.</span></li>
                                <li className="list-bottom"><b>Career Growth:</b><span className="app-desc-txt-color"> We create an ecosystem where security professionals thrive and develop their expertise.</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 image-contain">
                        <img src={images.connect} className="img-fluid rounded-3 " alt="Freelancer Platform" />
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12 col-md-12">
                       
                        <p className="home-service-content-text-box-txt ">
                            How to<span className="app-color"> Get Started</span>
                        </p>


                        <blockquote className="blockquote">
                                <p className="base-color start-text">
                                    “Insta-guard is designed to transform how security is ordered, creating a more efficient, cost-effective model that benefits both businesses and local communities. By connecting clients directly with vetted security professionals, we reduce overhead costs, allowing for more competitive pricing without compromising on quality. This approach boosts local employment opportunities, ensuring money stays within the community and supports the local economy. With Insta-guard, businesses get fast, reliable security, and professionals gain more job opportunities—strengthening security while driving economic growth.”
                                </p>
                            </blockquote>
                            
                        <div>
                        <ol className="base-color list-font-size">
                                <li className="list-bottom"><b>Select Your Service: </b><span className="app-desc-txt-color">Choose the type of security you need</span></li>
                                <li className="list-bottom"><b> Set Your Requirements: </b><span className="app-desc-txt-color"> Input the dates, times, and specific job details.</span></li>
                                <li className="list-bottom"><b>Review & Hire: </b><span className="app-desc-txt-color"> Instantly connect with local security professionals who meet your criteria.</span></li>
                            </ol>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
};
