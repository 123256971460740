import { IoIosArrowRoundForward } from "@react-icons/all-files/io/IoIosArrowRoundForward";
import React from "react";
import { useNavigate } from "react-router-dom";
import store from "../Redux/Store";
import { images } from "../images";
import playstore from "../asserts/google.png";
import appstore from "../asserts/apple.png";
import { IoCallOutline } from "@react-icons/all-files/io5/IoCallOutline";

function Footer() {
  const navigate = useNavigate();

  const handleNavigate = (value) => {
    navigate(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="footer-cls">
      <div className="container footer-top">
        <div className="footer-top-con">
          <div className="footer-img">
            <img src={images.sendIcon} alt="send"></img>
            <div>
              <p className="footer-head">Post a Request</p>
              <p className="footer-desc">
                use this link to contact us 24 hours a day
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center ">
            <div>
              <button
                className="book-guard-btn"
                onClick={() => {
                  navigate("/services");
                  store.dispatch({ type: "setType", payload: 2 });
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Book a Guard &nbsp;
                <IoIosArrowRoundForward size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-12 col-9 mt-md-4">
            <img
              role={"button"}
              className=""
              src={images.logo}
              onClick={() => handleNavigate("/")}
              alt="footer-logo"
            />
            <br />
            <img
              role={"button"}
              className="mt-1"
              src={require("../asserts/demoe.gif")}
              alt="footer-logo"
              height={130}
              onClick={() => handleNavigate("/")}
              style={{ objectFit: "contain" }}
            />
            {/* <p className="footer-con">Keeping you and your property safe</p> */}
            {/* <p className="footer-con">
              We have <span className="red-txt">NOT</span> come to{" "}
              <span className="red-txt">BE</span> serve
              <span className="red-txt">D</span>
            </p> */}
          </div>
          <div className="col-xl-8 mt-4">
            <div className="row justify-content-center">
              <div className="col-md-4 col-9 p-0">
                <ul className="footer-list">
                  <li className="footer-list-head">
                    <a>Company</a>
                  </li>
                  <li className="footer-list-con">
                    <a role="button" onClick={() => handleNavigate("/")}>
                      home page
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/services")}
                    >
                      Services
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a role="button" onClick={() => handleNavigate("/aboutUs")}>
                      About us
                    </a>
                  </li>
                  {/* <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/howitworks")}
                    >
                      How it works
                    </a>
                  </li> */}
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/contactUs")}
                    >
                      Contact us
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => window.location.href = '/recruitment'}
                    >
                      Recruitment
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-9 p-0">
                <ul className="footer-list">
                  <li className="footer-list-head">
                    <a>Legal</a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/privacypolicy")}
                    >
                      Privacy policy
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/termsConditions")}
                    >
                      Terms & conditions
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/refundpolicy")}
                    >
                      Refund policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-9 p-0">
                <ul className="footer-list">
                  <li className="footer-list-head">
                    <a>Support</a>
                  </li>
                  <li className="footer-list-con">
                    <a role="button" style={{textTransform:"none"}}>info@insta-guard.co.uk</a>
                  </li>
                  <li>
                    <a href="https://m.facebook.com/profile.php?id=61554269803826&name=xhp_nt__fb__action__open_user" target="blank">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                      </svg>
                    </a>
                    <a className="ms-3" href="https://www.linkedin.com/company/insta-guard?trk=profile-position" target="blank">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                      </svg>
                    </a>
                    {/* <a className="ms-3" href="tel:0800 023 4480" target="blank">
                      < IoCallOutline size="2.3em"/>
                      </a> */}
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/developer?id=FCEM+LIMITED" target="blank">
                      <img style={{width: "200px"}} src={playstore} alt="playstore"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://apps.apple.com/in/developer/insta-guard/id1735265056" target="blank">
                      <img style={{width: "200px"}} src={appstore} alt="appstore"/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="copy-right">
        Copyright © 2025. Psalm 23 Ltd trading as Insta-guard
      </p>
    </div>
  );
}

export default Footer;
