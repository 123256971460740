import React, { useEffect, useState } from "react";
import FetchApi from "../config/ApiCal";
import AutoCompleteAntd from "../View/AutoCompleteAntd";
import toast from "react-hot-toast";
import { CheckCircleOutlined } from "@ant-design/icons"; 
import { Box,  Typography, CircularProgress, Grid } from "@mui/material";
import QuestionInput from "../View/QuestionInput";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const contactQuestions = [
    { question: 'Name', field: 'name', type: 'text' },
    { question: 'Email', field: 'email', type: 'email' },
    { question: 'Phone', field: 'phone', type: 'text' },
];


  
const Question = ({ isOpen, onClose, selectedService, selectedPost, postcodSearch, loading, qa, subQa, setQa, setSubQa, contactMessage }) => {

    const [questions, setQuestions] = useState(qa);
    const [answers, setAnswers] = useState({});
    const [questionHistory, setQuestionHistory] = useState([]); 
    const [otherAns, setOtherAns] = useState({});
    const [content, setContent] = useState('questions');

    const [error, setError] = useState({ status: false, message: '' });
    const [contact, setContact] = useState({ name: '', email: '', phone: ''});
    const [cancelConfirm, setCancelConfirm] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [otherInput, setOtherInput] = useState('');

    const currentQuestion = questions[currentQuestionIndex];
    const progressPercentage = (questionHistory.length / questions.length) * 100;
    
    const handleInputChange = (option, type, extra = null) => {
        if (type === 'checkbox') {
            setAnswers((prev) => {
                const prevAnswers = prev[currentQuestion.id] || [];
                const updatedAnswers = prevAnswers.includes(option)
                    ? prevAnswers.filter((item) => item !== option) 
                    : [...prevAnswers, option];  

                return { ...prev, [currentQuestion.id]: updatedAnswers };
            });
        } else if (type === 'dropdown' || type === 'radio') {
            setAnswers((prev) => ({
                ...prev,
                [currentQuestion.id]: [option],
            }));
        }else if(type === 'datetime') {
            setAnswers((prev) => ({
                ...prev,
                [currentQuestion.id]: {
                    ...(prev[currentQuestion.id] || {}), 
                    [extra.index]: {
                        ...(prev[currentQuestion.id]?.[extra.index] || {}), // Preserve existing index data
                        [extra.field]: option // Update only the selected field (date or time)
                    }
                }
            }));
            
        }else if(type === 'text'){
            setAnswers((prev) => ({...prev, [currentQuestion.id]: option}));
        } else if(type === 'location'){
            setAnswers((prev) => ({...prev, [currentQuestion.id]: option}))
        }
    };
    
    const handleNext = () => {
       
        setError({ status: false, message: "" });

        if(content === 'postcode'){
            if(!selectedPost){
                setError({ status: true, message: "Search your city or post code." });
                return;
            }
            else {
                setContent('contact')
            }
        }

        if(content === 'questions'){

            let nextQuestionId = null;
            let selectedAnswer = answers[currentQuestion.id];
            if (currentQuestion.input_type == 4) {
                if(!selectedAnswer || selectedAnswer == ''){
                    setError({ status: true, message: "Please metion your answer" });
                    return;
                }
                
                nextQuestionId = currentQuestion.answers[0]?.redirect_qa_position_id;
            }   
            else if (currentQuestion.input_type == 5) {

                if (!selectedAnswer || typeof selectedAnswer !== "object") {
                    setError({ status: true, message: "Please select a date and time" });
                    return;
                }
                
                for (const key in selectedAnswer) {
                    if (selectedAnswer.hasOwnProperty(key)) {
                        const option = selectedAnswer[key];
                
                        if (!option || typeof option !== "object") {
                            setError({ status: true, message: "Invalid selection" });
                            return;
                        }
                        const { date, time } = option;
                        if (!date) {
                            setError({ status: true, message: "Please select a date" });
                            return;
                        } else if (!time) {
                            setError({ status: true, message: "Please select a time" });
                            return;
                        }
                    }
                }

                nextQuestionId = currentQuestion.answers[0]?.redirect_qa_position_id;

            } else if(currentQuestion.input_type == 6){

                if(!selectedAnswer || !selectedAnswer?.description || selectedAnswer.description == ''){
                    setError({ status: true, message: "Please select a location" });
                    return;
                }

                nextQuestionId = currentQuestion.answers[0]?.redirect_qa_position_id;
            }
            else{
                if((!selectedAnswer || (Array.isArray(selectedAnswer) && !selectedAnswer.length))){
                    setError({ status: true, message: "Please select an option." });
                    return;
                } 
                if(selectedAnswer.includes('Other') && !otherInput && otherInput == ''){
                    setError({ status: true, message: "Please metion your answer" });
                    return;
                } else {
                    setOtherAns((prev) => ({...prev, [currentQuestion.id]: otherInput}));
                    setOtherInput('');
                }

                nextQuestionId = currentQuestion.answers.find((option) => selectedAnswer.includes(option.name))?.redirect_qa_position_id;
            }

            if(currentQuestion?.category_qa_status){
                const currentOptions = currentQuestion.answers;            
                let s = currentOptions.find((op) => op.name == selectedAnswer);
                if (s?.sub_service_id) {
                    s = subQa.find((qa) => qa.id == s.sub_service_id);
                    let k = s.sub_category_questions;
                    if (k.length) {
                        setQuestions((prev) => [...prev, ...k]);
                        setCurrentQuestionIndex((prev) => prev + 1);
                        setQuestionHistory((prev) => [...prev, currentQuestionIndex]);
                        return;
                    } else {
                        questionTocontact();
                    }
                } else{
                    // setCurrentQuestionIndex((prev) => prev + 1);
                    // setQuestionHistory((prev) => [...prev, currentQuestionIndex]);
                    
                }
    
            }    

            
            if(!nextQuestionId || nextQuestionId == 'null' || nextQuestionId == currentQuestion?.postion_id || nextQuestionId == 'End'){
                questionTocontact();
            } 
            else {
                const nextIndex = nextQuestionId ? questions.findIndex((q) => q.position_id == nextQuestionId)  : -1;
                if (nextIndex !== -1) {
                    setQuestionHistory((prev) => [...prev, currentQuestionIndex]); 
                    setCurrentQuestionIndex(nextIndex);
                } else {
                    questionTocontact();
                }
            } 
            
        }
        
       

        if (content === 'contact' && contactValidate()) {

            const {format, cl} = formatAnswers();
            const data = {
                questions: format,
                customer: {
                    ...contact, mobile: contact.phone, category_name: selectedService?.category_name,
                    category_id: selectedService?.id, location: selectedPost?.description || cl
                }
            };

            submitApi(data);
            setContent('submitloading');

        }
        
    };

    const questionTocontact = () =>{
        let c = questions.findIndex((q => q.input_type == 6));
        if(postcodSearch.showPost && c === -1){
            setContent('postcode');
        } else{
            setContent('contact')
        }
    }

    const handleBack = () => {
        
        setError({...error, status: false});

        if(content === 'postcode'){
            setContent('questions');
        }
         
        if(content === 'contact'){
            let c = questions.findIndex((q => q.input_type == 6))
            if(postcodSearch.showPost && c === -1){
                setContent('postcode');
            } else{
                setContent('questions');
            }
        }
        
        if (content === 'questions' && questionHistory.length > 0) {
            const previousIndex = questionHistory.pop();
            setQuestionHistory([...questionHistory]); 
            setCurrentQuestionIndex(previousIndex);
            let s = questions[previousIndex];
            const currentOptions = s.answers;

            let ans = answers[s.id];
            if(Array.isArray(ans) && ans.includes('Other')){
                setOtherInput(otherAns[s.id])
            }
            if(s.category_qa_status){
                if(currentOptions[0]?.sub_service_id){
                    setQuestions(qa);  
                }
            }
        } 
    };

    const submitApi = async(data) => {
        try{
            const res = await FetchApi('emailToAdmin', data);

            if(res?.Success == 'mail send success'){
                toast.success("Thanks for your submission!");
                setContent('submitsuccess');
                
            } else{
                toast.error(res?.message || 'Something wrong');
                setContent('contact');
            }
        } catch(err){
            console.log(err);
            setContent('contact');
        }
        
    }

    const formatAnswers = () => {
        let res = [];
        let cl = null;
        Object.keys(answers).forEach((k) => {
            let question = questions.find((q) => q.id == k);
            let opt = question?.answers.map((a) => a.name);
            if (question) {
                let a = [];
                if(question.input_type == 4){
                    a = [answers[k]]
                } else if(question.input_type == 5){
                    let ans = answers[k];
                    a = Object.values(ans).map(s => `${s.date} ${s.time}`);
                } else if(question.input_type == 6){
                    let ans = answers[k];
                    a = [`${ans?.description}`];
                    cl = `${ans?.description}`;
                } else {
                    a = answers[k]
                }
                res.push({
                    id: k,
                    question: question?.question,
                    input_type: question?.input_type,
                    options: opt,
                    answers: a,
                    others: otherAns[k] || ''
                });
            }
        });
        return {format: res, cl: cl};
    };

    const contactValidate = () => {
        const errors = {};
        const { name, email, phone } = contact;

        if (!name.trim()) {
            errors.name = "Name is required";
        } else if (name.length < 2 || name.length > 50 || !/^[a-zA-Z\s]+$/.test(name)) {
            errors.name = "Invalid name format";
        }

        if (!phone.trim() || !/^\d{7,13}$/.test(phone)) {
            errors.phone = "Mobile number must be a valid number";
        }
        

        if (!email.trim() || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
            errors.email = "Please enter a valid email address";
        }

        if (Object.keys(errors).length) {
            setError({ ...errors, status: true });
            return false;
        }

        setError({ status: false });
        return true;
    };

    const handleQuit = () => {
        setCancelConfirm(false);
        setContact({ name: "", email: "", phone: "" });
        setCurrentQuestionIndex(0);
        setQuestions([]);
        setAnswers({});
        setOtherAns({})
        setQuestionHistory([])
        setQa([]);
        setSubQa([]);
        onClose();
        setContent('questions');
    };

    useEffect(() => {
        if(qa.length){
            setQuestions(qa); 
        }
    }, [qa])



    let loadingCotent = (
        <div style={{ padding: "50px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div
                    className="d-flex align-items-center gap-3  px-5 text-white letter-space"
                    style={{
                        background: "rgba( 131, 43, 103, 0.55 )",
                        boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.3 )",
                        backdropFilter: "blur( 20px )",
                        borderRadius: "10px",
                        border: "1px solid rgba( 255, 255, 255, 0.3 )",
                        height: 70,
                    }}
                >
                    <CircularProgress
                        style={{
                            color: "#2a2f83",
                        }}
                    />{" "}
                    Loading
                </div>
            </div>
        </div>
    );

    let confirmQuitContent = (<div style={{ padding: "20px 40px" }}>
        <h4>Are you sure that you want to leave?</h4>
        <p>We're asking a few questions so we can find you the right pros, and send you quotes fast and free!</p>
        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row-reverse", marginTop: "50px" }}>

            <button
                onClick={() => setCancelConfirm(false)}
                style={{
                    padding: "10px 20px",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "all 0.3s ease"
                }}
                className="bg-base-color base-hover"
            > Continue
            </button>

            <button
                onClick={handleQuit}
                className="back-btn"
                style={{
                    padding: "10px 20px",
                    background: "none",
                    color: "rgb(142 134 134)",
                    border: "1px solid rgb(164 165 166)",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "all 0.3s ease"

                }}>Quit</button>


        </div>
    </div>
    )

    const renderModalContent = () => {
        switch (content) {
            case "contact":
                return (
                    <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px', borderRadius: '8px', }}>
                        <p style={{ fontSize: '18px', color: '#555', marginBottom: '20px', textAlign: 'center', fontWeight: 'bold' }}>
                            {contactMessage || "Would you like me to generate a quote based on your selections? Please provide your contact information below."}
                        </p>

                        {contactQuestions.map((val, index) => (
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '20px',
                                    }}
                                >
                                    <label
                                        htmlFor={val.field}
                                        style={{
                                            fontSize: '16px',
                                            color: '#333',
                                            fontWeight: '500',
                                            width: '40%',
                                        }}
                                        className="hide-label"
                                    >
                                        {val.question}
                                    </label>
                                    <input
                                        type={val.type}
                                        name={val.field}
                                        value={contact[val.field]}
                                        onChange={(e) =>
                                            setContact({ ...contact, [val.field]: e.target.value })
                                        }
                                        placeholder={`Enter your ${val.question.toLowerCase()}`}
                                        style={{
                                            padding: '12px 15px',
                                            fontSize: '14px',
                                            borderRadius: '5px',
                                            border: error[val.field] ? '1px solid red' : '1px solid #ddd',
                                            outline: 'none',
                                            transition: '0.3s ease',
                                            width: '60%',
                                            boxShadow: error[val.field]
                                                ? '0 2px 8px rgba(255, 0, 0, 0.2)'
                                                : '0 2px 8px rgba(0, 0, 0, 0.1)',
                                        }}
                                        className='contact-field-space'
                                    // onFocus={() => setError({...error, [val.field]: ''})}
                                    />
                                </div>
                                {error.status && error[val.field] && (
                                    <p style={{ color: 'red', marginTop: '5px', textAlign: 'end' }}>* {error[val.field]}</p>
                                )}
                            </div>
                        ))}

                        {/* Submission Information */}
                        <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '30px', marginBottom: '-15px', fontStyle: 'italic' }} className="app-color">
                            You will receive a quotation within the hour.
                        </p>


                    </div>
                )

            case "questions":
                return (
                    <>
                        <div
                            style={{
                                width: "70%",
                                backgroundColor: "#e0e0e0",
                                borderRadius: "10px",
                                overflow: "hidden",
                                marginBottom: "20px",
                                marginLeft: "15%"
                            }}
                        >
                            <div
                                style={{
                                    width: `${progressPercentage}%`,
                                    backgroundColor: "#832b67",
                                    height: "7px",
                                    transition: "width 0.3s ease",
                                }}
                            />
                        </div>
                        {/* Question Content */}
                        <p className="quesion-text" style={{ fontSize: "24px", color: "#111637", marginBottom: "40px", marginTop:"20px", fontWeight: "500" }}>
                            {currentQuestion?.question}
                        </p>

                        {/* Error Content */}
                        {error.status &&
                            <p style={{ background: 'rgb(253, 239, 241)', color: 'rgb(239, 98, 119)', padding: "20px", textAlign: 'left' }}>{error.message}</p>
                        }

                        <div style={{ textAlign: "left", marginBottom: "20px" }}>
                            {currentQuestion ? (
                                <QuestionInput 
                                    currentQuestion={currentQuestion}
                                    currentQuestionIndex={currentQuestionIndex}
                                    handleInputChange={handleInputChange}
                                    answers={answers}
                                    otherInput={otherInput}
                                    setOtherInput={setOtherInput}
                                />
                            ) : null}
                        </div>


                    </>
                )
            case "postcode":
                return (
                    <div className="mt-4" style={{  marginBottom: "10px" }}> 
                        <p className="quesion-text" style={{ fontSize: "24px", color: "#111637", marginBottom: "10px", fontWeight: "500" }}>
                            Please search your city or post code
                        </p>

                        {error.status &&
                            <p style={{ background: 'rgb(253, 239, 241)', color: 'rgb(239, 98, 119)', padding: "20px", textAlign: 'left', margin: '0 20px' }}>
                                {error.message}</p>
                        }
                        <div style={{padding:"20px"}}>
                            <AutoCompleteAntd searchData={{...postcodSearch, selectedData: selectedPost}}/>
                        </div>
                        
                       
                    </div>
                  
                )
            case 'submitsuccess':
                return(
                    <div style={{ marginTop: "50px", animation: "fadeIn 0.5s ease-in-out" }}>
                        <CheckCircleOutlined style={{ fontSize: "80px", color: "#28a745" }} />
                        <h2 style={{ color: "#333", marginTop: "20px" }}>Thank You!</h2>
                        <p style={{fontWeight: '700'}} >Your response has been submitted successfully.</p>
                        <p className="app-color">Thanks for your submission!  We'll send your quote soon.</p>
                    </div>
                )
            case 'submitloading':
                return(
                    <div style={{ margin: "50px 0px", animation: "fadeIn 0.5s ease-in-out" }}>
                        <CircularProgress sx={{ color: "#2a2f83", mb: 2 }} />
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#832b67" }}>
                            Your request is processing please wait!.
                        </Typography>
                    </div>
                )


                
        }
    }


    if (!isOpen) return null;

    return (
        <div className="question-modal"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 10,
                overflow: 'auto',
            }}>
            <div style={{ display: "flex", justifyContent: "center", }} >

                {loading ? loadingCotent :
                    <div
                        style={{
                            width: "600px",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                            textAlign: "center",
                            margin: "10px",
                            marginBottom: "80px",
                            // background: content === 'showMatching' ? '#d3ffeb' : '#fff', 
                            background: '#fff', 
                        }}
                    >
                        {
                            cancelConfirm ? confirmQuitContent :
                                <>
                                    <div style={{ position: "relative", marginBottom: "20px" }}>
                                        {
                                            content != 'submitloading' && 
                                            <button
                                                onClick={() => content === 'submitsuccess' ?  handleQuit() :  setCancelConfirm(true)}
                                                style={{
                                                    position: "absolute",
                                                    top: "10px",
                                                    right: "10px",
                                                    backgroundColor: 'rgb(235 235 235)',
                                                    color: "#000",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    width: "37px",
                                                    height: "37px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    fontSize: "29px"
                                                }}
                                            >
                                            &times;
                                        </button>

                                        }
                                      
                                        {
                                            currentQuestionIndex == 0 && content === 'questions' &&
                                            <img
                                                src="https://cdn.pixabay.com/photo/2015/10/29/14/38/web-1012467_1280.jpg"
                                                alt="Banner"
                                                style={{ width: "100%", height: "150px", display: "block" }}
                                            />
                                        }

                                    </div>

                                    <div className="question-modal-content" style={{ padding: "20px 40px"}}>

                                        {renderModalContent()}

                                        {content != 'submitloading' && 
                                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row-reverse", marginTop: "50px" }}>

                                            {
                                                content === 'submitsuccess' ? <button
                                                    onClick={handleQuit}
                                                    style={{
                                                        padding: "10px 20px",
                                                        color: "#fff",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                        transition: "all 0.3s ease",
                                                        cursor: "pointer",
                                                       
                                                    }}
                                                    className="bg-base-color base-hover"
                                                >
                                                    Close
                                                </button> 
                                                :
                                                <button
                                                onClick={handleNext}
                                                style={{
                                                    padding: "10px 20px",
                                                    color: "#fff",
                                                    border: "none",
                                                    borderRadius: "5px",
                                                    transition: "all 0.3s ease",
                                                    cursor: "pointer",
                                                }}
                                                className="bg-base-color base-hover"
                                                >
                                                    {content === 'contact'  ? 'Submit' : 'Next'}
                                                </button>
                                                    

                                            }
                                            {
                                                currentQuestionIndex > 0 && content != 'submitsuccess'  &&
                                                <button
                                                    onClick={handleBack}
                                                    className="back-btn"
                                                    style={{
                                                        padding: "10px 20px",
                                                        background: "none",
                                                        color: "rgb(142 134 134)",
                                                        border: "1px solid rgb(164 165 166)",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        transition: "all 0.3s ease"

                                                    }}
                                                >{currentQuestionIndex > 0 ? 'Back' : 'Quit'}

                                                </button>
                                            }

                                        </div>
                                        }
                                    </div>
                                </>
                        }

                    </div>
                }
            </div>


        </div>
    );
};

export default Question;
