import React, { useState } from "react";
import {  DatePicker, Input, TimePicker } from "antd";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { FaLocationDot } from "react-icons/fa6";
import AutoCompleteAntd from "./AutoCompleteAntd";
import TextArea from "antd/es/input/TextArea";


export default function QuestionInput(props) {

    const { currentQuestion, answers, handleInputChange,
        currentQuestionIndex, otherInput, setOtherInput } = props;

    let type = '';

    if (currentQuestion.input_type == 1) {
        type = 'checkbox';
    } else if (currentQuestion.input_type == 2) {
        type = 'radio'
    } else if (currentQuestion.input_type == 3) {
        type = 'dropdown'
    } else if (currentQuestion.input_type == 4) {
        type = 'text'
    } else if (currentQuestion.input_type == 5) {
        type = 'datetime';
    } else if (currentQuestion.input_type == 6){
        type = 'location';
    }

    switch (type) {
        case 'radio':
            return (
                currentQuestion.answers?.map((option, index) => (
                    <div
                        key={index}
                        style={{
                            marginBottom: "10px",
                            padding: "10px",
                            border: "1px solid rgb(237 234 234)",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer"
                        }}
                        onClick={() => handleInputChange(option.name, type)}
                        className="question-options"
                    >
                        <div className="options-text"
                            style={{
                                display: "flex", alignItems: "center", fontFamily: "inherit", cursor: "pointer",
                                color: answers[currentQuestion.id]?.includes(option.name) ? '#111637' : '#676b8b'
                            }} >
                            <input
                                type="radio"
                                name={`question-${currentQuestionIndex}`}
                                checked={answers[currentQuestion.id]?.includes(option.name) || false}
                                style={{ marginRight: "10px", width: "20px", height: "20px" }}
                                className="question-checkbox"

                            />
                            {option.name}

                        </div>
                        {option.name == "Other" &&

                            <input
                                type="text"
                                value={otherInput}
                                onChange={(e) => setOtherInput(e.target.value)}
                                placeholder="please specify"
                                style={{
                                    marginLeft: "10px",
                                    padding: "8px",
                                    width: "100%",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    outline: 'none',
                                    flex: 1,
                                }}

                            />

                        }
                    </div>

                ))
            );

        case 'checkbox':
            return (
                <>
                    {currentQuestion.answers?.map((option, index) => (
                        <div
                            key={index}
                            style={{
                                marginBottom: "10px",
                                padding: "10px",
                                border: "1px solid rgb(237 234 234)",
                                borderRadius: "5px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer"
                            }}
                            onClick={() => handleInputChange(option.name, type)}
                            className="question-options"
                        >
                            <div className="options-text"
                                style={{
                                    display: "flex", alignItems: "center", fontFamily: "inherit", cursor: "pointer",
                                    color: answers[currentQuestion.id]?.includes(option.name) ? '#111637' : '#676b8b'
                                }} >
                                <input
                                    type="checkbox"
                                    name={`question-${currentQuestionIndex}`}
                                    checked={answers[currentQuestion.id]?.includes(option.name) || false}
                                    style={{ marginRight: "10px", width: "20px", height: "20px" }}
                                    className="question-checkbox"

                                />
                                {option.name}
                            </div>

                            {option.name == "Other" &&
                                <>

                                    <input
                                        type="text"
                                        value={otherInput}
                                        placeholder="please specify"
                                        style={{
                                            marginLeft: "20px",
                                            padding: "8px",
                                            width: "100%",
                                            border: "1px solid #ccc",
                                            borderRadius: "5px",
                                            outline: 'none',
                                            flex: 1,
                                        }}
                                        onChange={(e) => setOtherInput(e.target.value)}
                                        onClick={(e) => e.stopPropagation()}

                                    />
                                </>

                            }
                        </div>

                    ))}
                </>
            );

        case 'dropdown':
            return (
                <div className="mb-4 p-3" >
                    <label
                        htmlFor={`dropdown-${currentQuestionIndex}`}
                        className="form-label fw-bold"
                        style={{
                            fontSize: "16px",
                            color: "#333",
                            marginBottom: "10px",
                        }}
                    >
                        Select an Option:
                    </label>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle w-100 text-start"
                            type="button"
                            id={`dropdownButton-${currentQuestionIndex}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                                padding: "12px 15px",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                fontSize: "15px",
                                backgroundColor: "#ffffff",
                                color: "#333",
                                transition: "box-shadow 0.3s ease, border-color 0.3s ease",
                            }}
                            onMouseOver={(e) => (e.target.style.boxShadow = "0 0 5px rgba(0, 123, 255, 0.5)")}
                            onMouseOut={(e) => (e.target.style.boxShadow = "none")}
                        >
                            {answers[currentQuestion?.id] || "-- Select an option --"}
                        </button>
                        <ul
                            className="dropdown-menu w-100 shadow-sm"
                            aria-labelledby={`dropdownButton-${currentQuestionIndex}`}
                            style={{
                                borderRadius: "8px",
                                overflow: "hidden",
                                backgroundColor: "#ffffff",
                                border: "1px solid #ddd",
                                marginTop: "5px",
                                padding: "0",
                            }}
                        >
                            {currentQuestion.answers.map((option, index) => (
                                <li key={index}>
                                    <button
                                        className="dropdown-item"
                                        onClick={() => handleInputChange(option.name, type)}
                                        style={{
                                            padding: "10px 15px",
                                            fontSize: "15px",
                                            color: "#333",
                                            transition: "background-color 0.3s ease, color 0.3s ease",
                                        }}
                                        onMouseOver={(e) => {
                                            e.target.style.backgroundColor = "#2a2f83";
                                            e.target.style.color = "#fff";
                                        }}
                                        onMouseOut={(e) => {
                                            e.target.style.backgroundColor = "#fff";
                                            e.target.style.color = "#333";
                                        }}
                                    >
                                        {option.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            );

        case 'text':
            return (
                <TextArea
                    multiline
                    placeholder="Mention your answer"
                    style={{
                        fontWeight: "bold",
                        width: "100%",
                        outline: "none",
                        borderRadius: "5px",
                        padding: "10px",
                        backgroundColor: "#fff",
                        boxSizing: "border-box",
                        border: "1px solid #ccc",
                        fontSize: '16px'
                    }}
                    value={answers[currentQuestion.id] || ''}
                    onChange={(e) => handleInputChange(e.target.value, 'text')}
                    onFocus={(e) => {
                        e.target.style.borderBottom = "2px solid #2a2f83"; 
                        // e.target.style.border = "2px solid #2a2f83"; 
                    }}
                    onBlur={(e) => {
                        e.target.style.border = "2px solid #ccc"; 
                    }}
                />
            );

        case 'datetime':
            return (
                currentQuestion?.answers.map((option, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                        <p style={{ textAlign: 'center' }} className="base-color">{option?.name}</p>
                        <div style={{ display: 'flex', gap: '15px', width: '100%', flexWrap: 'wrap' }}>
                            <div style={{ flex: 1, minWidth: '200px' }}>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD"
                                    placeholder="Select Date"
                                    className="custom-date-picker"
                                    value={answers[currentQuestion.id]?.[index]?.date ? dayjs(answers[currentQuestion.id]?.[index].date) : null}
                                    onChange={(date) => handleInputChange(date ? date.format("YYYY-MM-DD") : null, type, {field: 'date', index})}
                                    disabledDate={(current) => current && current < dayjs().startOf("day")}
                                />
                            </div>
                
                            <div style={{ flex: 1, minWidth: '200px' }}>
                                <TimePicker
                                    style={{ width: '100%' }}
                                    format="hh:mm A"
                                    placeholder="Select Time"
                                    className="custom-time-picker"
                                    value={answers[currentQuestion.id]?.[index]?.time ? dayjs(answers[currentQuestion.id][index].time, "hh:mm A") : null}
                                    onChange={(time) => handleInputChange(time ? time.format('hh:mm A') : null, type, {field: 'time', index})}
                                />
                            </div>
                        </div>
                    </div>
                ))
                
               
            );
        
        case 'location':
            const locationSearch = {
                api: 'location', placeholder: 'Post code',
                icon: <FaLocationDot style={{ color: '#000', marginRight: "5px" }} />,
                setSelect: handleInputChange,
                selectedData: answers[currentQuestion.id],
                type: 'location'
              };
            
            return(
                <div className="mt-4" style={{ marginBottom: "10px" }}>

                    <div style={{ padding: "20px" }}>
                        <AutoCompleteAntd searchData={locationSearch} />
                    </div>


                </div>
          
            )
            
        default:
            return null;
    }
}
