import { Avatar } from "@mui/material";
import { BiLogOut } from "@react-icons/all-files/bi/BiLogOut";
import { CgClose } from "@react-icons/all-files/cg/CgClose";
import { CgMenuRight } from "@react-icons/all-files/cg/CgMenuRight";
import { CgProfile } from "@react-icons/all-files/cg/CgProfile";
import { RiUserFill } from "@react-icons/all-files/ri/RiUserFill";
import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import store from "../Redux/Store";
import FetchApi from "../config/ApiCal";
import { imageUrl } from "../config/Const";
import { images } from "../images";
import "../styles/Header.css";
import { IoCall } from "@react-icons/all-files/io5/IoCall";
import { IoMail } from "react-icons/io5";

import guardIcon from '../icons/policeman.png';


function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [user, setUser] = useState(null);

  const LoginData = useSelector((state) => state.LgnData);

  // console.log("user details ->", LoginData);

  useEffect(() => {
    let isAuth = Object.entries(LoginData).length > 0 ? LoginData.id : null;
    setUser(isAuth);
    setExpanded(false);
    store.dispatch({type:"setAuth", payload: true});
  }, [LoginData]);

  // console.log(user);
  useEffect(() => {
    const currentURL = location.pathname;
    setMenuActive(currentURL);
    // console.log(currentURL);
  }, [location]);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    FetchApi('planDetail', {
      customer_id: LoginData.id
    }).then(e => {

      setPlanStatus(e.result)

    })
  }, []);
  const [planStatus, setPlanStatus] = useState([]);
  const LogOut = () => {
    FetchApi('DeviceTokenWeb', {
      device_token: '',
      id: LoginData?.id,
      mode: 'logout'
    })
    store.dispatch({ type: "Logout" });
    store.dispatch({type:"setAuth", payload: null});
    localStorage.clear();
    setUser("");
    toast.success("Logged out Successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      navigate("/");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 3000);
  };

  const items = [
    {
      key: "1",
      label: (
        <div>
          <span
            className="list-item mx-2"
            style={{ color: "#1585FF", fontWeight: "500" }}
            role="button"
            onClick={() => navigate("/profile")}
          >
            <CgProfile />
            &nbsp;&nbsp;My Account
          </span>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <div>
    //       <a
    //         className="list-item mx-2"
    //         style={{ color: "#1585FF", fontWeight: "500" }}
    //         role="button"
    //         onClick={() => navigate('subscriptionHistory')}
    //       >
    //         <MdVerified /> &nbsp;&nbsp;Member Ship
    //       </a>
    //     </div>
    //   ),
    // },
    {
      key: "3",
      label: (
        <div>
          <span
            className="list-item mx-2"
            style={{ color: "#1585FF", fontWeight: "500" }}
            role="button"
            data-bs-toggle="modal"
            data-bs-target="#logout"
          >
            <BiLogOut />
            &nbsp;&nbsp;Logout
          </span>
        </div>
      ),
    },
  ];
  const transferPage = () => {
    if (planStatus && planStatus.length === 0) {
      navigate('/subscriptionPlans')
    } else {
      navigate('/subscriptionHistory')
    }
    setExpanded(false);
  }

  return (
    <>
      <nav className="navbar navbar-expand-md bg-white ">
        <div className="container p-2">
          <span
            role="button"
            className="navbar-brand"
            onClick={() => navigate("/")}
          >
            <img
              role={"button"}
              className="logo-img"
              src={images.logo}
              alt="user"
            />
          </span>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
          >
            {expanded ? (
              <span className={expanded ? "" : "hidden"}>
                {" "}
                <CgClose size={26} />
              </span>
            ) : (
              <span className={!expanded ? "" : "hidden"}>
                <CgMenuRight size={26} />
              </span>
            )}
          </button>
          <div className={`collapse navbar-collapse${expanded ? " show" : ""}`}>
            <ul className="navbar-nav ml-auto">
              {user && user != null ? (
                <>
                  <li className="nav-item active">
                    <span
                      className={
                        menuActive == "/"
                          ? "nav-link list-item list-item-act"
                          : "nav-link list-item"
                      }
                      role="button"
                      onClick={() => {
                        navigate("/");
                        setExpanded(false);
                      }}
                    >
                      Home
                    </span>
                  </li>
                  <li className="nav-item active">
                    <span
                      className={
                        menuActive == "/bookingDetails"
                          ? "nav-link list-item list-item-act"
                          : "nav-link list-item"
                      }
                      role="button"
                      onClick={() => {
                        navigate("/bookingDetails", {
                          state: 1,
                        });
                        setExpanded(false);
                      }}
                    >
                      Request
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={
                        menuActive == "/services"
                          ? "nav-link list-item list-item-act"
                          : "nav-link list-item"
                      }
                      role="button"
                      onClick={() => {
                        navigate("/services");
                        setExpanded(false);
                      }}
                    >
                      Services
                    </span>
                  </li>
                  <li className="nav-item active">
                    <span
                      className={
                        menuActive == "/aboutUs"
                          ? "nav-link list-item list-item-act"
                          : "nav-link list-item"
                      }
                      role="button"
                      onClick={() => {
                        navigate("/aboutUs");
                        setExpanded(false);
                      }}
                    >
                      About us
                    </span>
                  </li>

                  <li className="nav-item active">
                    <span
                      className={
                        menuActive == "/subscriptionPlans" || menuActive == "/subscriptionHistory"
                          ? "nav-link list-item list-item-act"
                          : "nav-link list-item"
                      }
                      role="button"
                      onClick={transferPage}
                    >
                      Membership
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={
                        menuActive == "/recruitment"
                          ? "nav-link list-item list-item-act"
                          : "nav-link list-item"
                      }
                      role="button"
                      onClick={() => {
                        window.location.href = '/recruitment'
                        setExpanded(false);
                      }}
                    >
                      Recruitment
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={
                        menuActive == "/contactUs"
                          ? "nav-link list-item list-item-act"
                          : "nav-link list-item"
                      }
                      role="button"
                      onClick={() => {
                        navigate("/contactUs");
                        setExpanded(false);
                      }}
                    >
                      Contact us
                    </span>
                  </li>
                  <li className="">
                    <Dropdown
                      menu={{
                        items,
                      }}
                      className="avatar"
                    >
                      <div>
                        <span role="button" className="avatar">
                          <Avatar
                            alt={LoginData.id ? LoginData.first_name : ""}
                            src={
                              LoginData &&
                                LoginData.avatar &&
                                LoginData.avatar != ""
                                ? imageUrl + LoginData.avatar
                                : images.user
                            }
                          />
                        </span>
                      </div>
                    </Dropdown>
                  </li>
                  <li className="">
                    <span
                      className={
                        menuActive == "/profile"
                          ? "header-pro nav-link list-item list-item-act"
                          : "header-pro nav-link list-item"
                      }
                      role="button"
                      onClick={() => {
                        navigate("/profile");
                        setExpanded(false);
                      }}
                    >
                      My Account
                    </span>
                  </li>
                  {/* <li>
                    {" "}
                    <a className="header-pro nav-link list-item" role="button">
                      Change Password
                    </a>
                  </li> */}
                  <li>
                    <span
                      className="header-pro nav-link list-item"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#logout"
                    >
                      Logout
                    </span>
                  </li>
                </>
              ) : (
                <>
                  <li
                    className="nav-item  header-login-btn"
                    style={{ color: "white" }}
                  >
                    <a
                      className="nav-link"
                      role="button"
                      onClick={() => navigate("/login")}
                    >
                      <RiUserFill color="white" size={22} />
                      &nbsp;&nbsp; Sign Up / Login
                    </a>
                  </li>
                  <li>
                  <div className="d-flex justify-content-end">
                    <a className="nav-link base-color" style={{borderRadius:"10px", padding:"8px 25px"}} role="button" onClick={() => navigate("/recruitment")}>
                      <img src={guardIcon}  style={{width:"30px", height:"25px", paddingRight:"6px"}}/>
                      Join as Security Freelancer 
                    </a>
                  </div>
                  </li>
                  
                </>

              )}
            </ul>
          </div>
        </div>


        <div className='d-flex flex-column gap-2 telephone-content'>
          <div className="telephone">
            <a href='tel:0333 577 2477' target='_blank' className='text-decoration-none d-flex justfiy-content-center' style={{ color: '#2a2f83' }}>
              <IoCall color='#832b67' size={"1.5em"} />
              <span className="ms-3">0333 577 2477</span>
            </a>
          </div>
          <div className="telephone">
            <a href='mailto:info@insta-guard.co.uk' target='_blank' className='text-decoration-none d-flex justfiy-content-center' style={{ color: '#2a2f83' }}>
              <IoMail color='#832b67' size={"1.5em"} />
              <span className="ms-3">info@insta-guard.co.uk</span>
            </a>
          </div>
        </div>

      </nav>

      <div
        className="modal fade"
        id="logout"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header logout-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Logout
              </h1>
              <button
                type="button"
                className="btn-close logout-close-btn"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <p className="text-center logout-desc">
                Are you sure want to logout?
              </p>
            </div>
            <div className="modal-footer d-flex justify-content-center gap-5 border-top-0">
              <button
                type="button"
                className="logout-yes"
                data-bs-dismiss="modal"
                onClick={LogOut}
              >
                Yes
              </button>
              <button
                type="button"
                className="logout-no"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
        {/* <ToastContainer
          position="top-right"
          autoClose={3000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> */}
        \
      </div>
    </>
  );
}

export default Header;
